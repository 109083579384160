const submitForm = async ({
    email,
    firstName,
    lastName,
    middleName,
    mobileNo,
    planId,
    autoRenew,
    tin,
    province,
    city,
    barangay,
    zipCode
}) => {
    try {
        const url = window.location.href;
        const [, stage, , flag] = url.split('.');
        const response = await fetch(
            `https://partners-api.${flag ? `${stage}.` : ''}bpimsapps.com/${
                autoRenew ? 'register' : 'register/onetime'
            }`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    policy: {
                        policyType: 'pci',
                        autoRenewTag: autoRenew,
                        planId,
                        customer: {
                            // email: email ? (email.toUpperCase ? email.toUpperCase() : email) : email,
                            email: email.toLowerCase(),
                            firstName: firstName
                                ? firstName.toUpperCase
                                    ? firstName.toUpperCase()
                                    : firstName
                                : firstName,
                            lastName: lastName ? (lastName.toUpperCase ? lastName.toUpperCase() : lastName) : lastName,
                            middleName: middleName
                                ? middleName.toUpperCase
                                    ? middleName.toUpperCase()
                                    : middleName
                                : middleName,
                            mobileNo,
                            gender: '',
                            tin,
                            province,
                            city,
                            barangay,
                            zipCode
                        }
                    }
                })
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export default submitForm;
