import React, { useRef } from 'react';
import styled from 'styled-components';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorModal from './Modal';
import ConfirmationModal from './ConfirmationModal';
import Select from 'react-select';

const StyledContainer = styled(Container)`
    // background-color: #ececec !important;
    background-color: transparent;
    color: #303030;
    padding: 4% 17%;
    position: relative;
`;

const StyledH1 = styled.h1`
    font-weight: 700;
    font-family: 'PoppinsSemiBold';
`;

const StyledP = styled.p`
    // color: rgba(255, 252, 252, 1);
    font-size: 15px;
    // font-weight: 400;
    font-family: 'PoppinsRegular';
`;

const StyledForm = styled(Form)`
    .form-group-label {
        font-family: 'PoppinsRegular';
        font-weight: normal;
        font-size: 14px;
    }
`;

// top right bottom left
const ClientPromtText = styled.div`
    padding: 50px 80px 0px 80px;
    font-family: 'PoppinsRegular';

    @media (max-width: 768px) {
        padding: 0px 0px 0 0px;
    }
`;

const SpinnerWrapper = styled.div`
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: block;
`;

export default function FormBody({
    handleData,
    data,
    handleSubmit,
    plan,
    planObject,
    formLoading,
    plans,
    setSelectedPlan,
    address,
    setAddress,
    loadingForm
}) {
    const [form, setForm] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [errorMessages, setErrorMessages] = React.useState({});
    const previousErrorsRef = React.useRef([]);
    const previousErrorsRef2 = React.useRef([]);
    const selectedPlanRef = useRef(null);

    const formRefs = {
        'FIRST NAME': useRef(),
        'MIDDLE NAME': useRef(),
        'LAST NAME': useRef(),
        'MOBILE NUMBER': useRef(),
        TIN: useRef(),
        EMAIL: useRef(),
        PROVINCE: useRef(),
        CITY: useRef(),
        BARANGAY: useRef()
    };

    React.useEffect(() => {
        if (previousErrorsRef?.current?.length) {
            const errorField = previousErrorsRef.current[0];
            setTimeout(() => {
                formRefs[errorField].current.focus();
            }, 400);
        }

        previousErrorsRef.current = errors.slice() || [];
    }, [errors]);

    // Access the previous value using previousErrorsRef.current

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        handleData((p) => {
            return {
                ...p,
                [name]:
                    type === 'checkbox'
                        ? checked
                        : name === 'mobileNo'
                        ? value.replace(/[^0-9]/g, '').slice(0, 10) // for mobile number, 10 digits only
                        : name === 'tin'
                        ? value.replace(/[^0-9]/g, '').slice(0, 14) // for TIN, 14 digits only
                        : value
            };
        });
    };

    const handleCheckboxChange = () => {
        handleData((p) => ({ ...p, agreed: !p.agreed }));
    };

    const handleRecurringCheckboxChange = () => {
        handleData((p) => ({ ...p, recurring: !p.recurring }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const formattedMobileNo = `+63-${data.mobileNo}`;
            const dataWithPrefix = { ...data, mobileNo: formattedMobileNo };
            handleValidate(data);
            setForm(() => dataWithPrefix);
            // handleSubmit(dataWithPrefix);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handleSubmitConfirm = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const formattedMobileNo = `+63-${data.mobileNo}`;
            const dataWithPrefix = { ...data, mobileNo: formattedMobileNo };
            console.log('dataWithPrefix: ', dataWithPrefix);
            handleSubmit(dataWithPrefix);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handleValidate = (_form) => {
        const messages = {};
        const errors = [];
        previousErrorsRef2.current = [];
        const regex = /^(?=.*[a-zA-Z\s])[a-zA-Z0-9\s]+$/;
        // const tinRegex =
            // /^(?!0)(?!\d*([1-9])\1{3,})(?!0{8,})(?!0123456789)(?!987654321)(?!123456789)\d{14}|00000000000000$/;
        const tinRegex = 
            /^(?!\d*([1-9])\1{3,})(?!0{8,})(?!0123456789)(?!987654321)(?!123456789)\d{14}|00000000000000$/;

        if (!_form.firstName || !regex.test(_form.firstName || '')) {
            errors.push('FIRST NAME');
            messages['FIRST NAME'] = !_form.firstName ? 'First name is required' : 'First name is invalid';
        }
        if (_form.middleName && !regex.test(_form.middleName || '')) {
            errors.push('MIDDLE NAME');
            messages['MIDDLE NAME'] = 'Middle name is invalid';
        }

        if (!_form.lastName || !regex.test(_form.lastName || '')) {
            errors.push('LAST NAME');
            messages['LAST NAME'] = !_form.lastName ? 'Last name is required' : 'Last name is invalid';
        }

        if (!_form.mobileNo || (_form?.mobileNo?.length ?? '') < 10) {
            errors.push('MOBILE NUMBER');
            messages['MOBILE NUMBER'] = !_form.mobileNo
            ? 'Mobile number is required'
            : 'Mobile number must be 10 digits long';
        }

        if (!_form.email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(_form.email)) {
            errors.push('EMAIL');
            messages['EMAIL'] = !_form.email ? 'Email is required' : 'Email is invalid';
        }

        if (!_form.tin || !tinRegex.test(_form.tin)) {
            errors.push('TIN');
            messages['TIN'] = "TIN must be 14 digits";
        }

        if (!_form.province) errors.push('PROVINCE');
        if (!_form.city) errors.push('CITY');
        if (!_form.barangay) errors.push('BARANGAY');

        if (errors.length) {
            previousErrorsRef2.current = errors.slice();
            setErrors(() => errors);
            setErrorMessages(() => messages);
            throw new Error('Error on validation', errors);
        }

        return;
    };

    const setSelectedPlans = (event) => {
        const selectedPlan = plans.filter((plan) => plan.id == event.target.value);
        setSelectedPlan(selectedPlan[0]);
    };

    const setSelectedType = (event) => {
        handleData((p) => ({ ...p, autoRenew: event.target.value == '1' ? true : false, recurring: false }));
    };

    const filterCity = (province) => {
        const matchedCity = address.city.filter((item) => item.provinceCd === province.provinceCd);

        handleInputChange({ target: { name: 'province', value: province.provinceName } });
        handleInputChange({ target: { name: 'city', value: null } });
        handleInputChange({ target: { name: 'barangay', value: null } });
        handleInputChange({ target: { name: 'zipCode', value: '' } });

        setAddress({
            ...address,
            matchedCity: matchedCity,
            matchedBarangay: null
        });
    };

    const filterBarangay = (city) => {
        const matchedBarangay = address.barangay.filter(
            (item) => item.provinceCd === city.provinceCd && item.cityCd === city.cityCd
        );

        handleInputChange({ target: { name: 'city', value: city.cityName } });
        handleInputChange({ target: { name: 'barangay', value: null } });
        handleInputChange({ target: { name: 'zipCode', value: '' } });

        setAddress({
            ...address,
            matchedBarangay: matchedBarangay
        });
    };

    React.useEffect(() => {
        if (plan !== undefined) {
            selectedPlanRef.current.value = plan;
        }
    }, [plan]);

    return (
        <StyledContainer fluid id="form-section">
            {loadingForm && (
                <SpinnerWrapper>
                    <Spinner animation="border" role="status" variant="dark" />
                </SpinnerWrapper>
            )}

            <Row style={{ opacity: loadingForm ? '0.4' : '' }}>
                <StyledH1>Get covered today!</StyledH1>
                <StyledP>
                    Fill-out the form below and enroll for Personal Cyber Insurance for your BPI Debit Mastercard®.
                </StyledP>
            </Row>

            <StyledForm style={{ opacity: loadingForm ? '0.4' : '', pointerEvents: loadingForm ? 'none' : '' }}>
                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                }}
                            >
                                FIRST NAME<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-group-input-custom"
                                name="firstName"
                                value={data.firstName}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['FIRST NAME']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('FIRST NAME')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('FIRST NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                }}
                            >
                                MIDDLE NAME
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-group-input-custom"
                                name="middleName"
                                value={data.middleName}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('MIDDLE NAME')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onChange={handleInputChange}
                                ref={formRefs['MIDDLE NAME']}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('MIDDLE NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                }}
                            >
                                LAST NAME<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-group-input-custom"
                                name="lastName"
                                value={data.lastName}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['LAST NAME']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('LAST NAME') ? 'red' : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('LAST NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="">
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                }}
                            >
                                MOBILE NO.<span>*</span>
                            </Form.Label>
                            <div className="input-group">
                                <span
                                    className="input-group-text custom-input"
                                    style={{
                                        borderColor: (previousErrorsRef2?.current ?? []).includes('MOBILE NUMBER')
                                            ? 'red'
                                            : '',
                                        textTransform: 'uppercase' ? 'red' : '',
                                        height: '45px'
                                    }}
                                >
                                    +63
                                </span>
                                <Form.Control
                                    className="custom-input"
                                    type="tel"
                                    name="mobileNo"
                                    value={data.mobileNo}
                                    onChange={handleInputChange}
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    required
                                    ref={formRefs['MOBILE NUMBER']}
                                    style={{
                                        borderColor: (previousErrorsRef2?.current ?? []).includes('MOBILE NUMBER')
                                            ? 'red'
                                            : '',
                                        textTransform: 'uppercase' ? 'red' : '',
                                        height: '45px'
                                    }}
                                    onFocus={() => {
                                        const index = previousErrorsRef2.current.indexOf('MOBILE NUMBER');
                                        if (index !== -1) {
                                            const _newArr = previousErrorsRef2.current.slice();
                                            _newArr.splice(index, 1);
                                            previousErrorsRef2.current = _newArr;
                                        }
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                }}
                            >
                                EMAIL<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                className="form-group-input-custom"
                                name="email"
                                value={data.email}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['EMAIL']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('EMAIL') ? 'red' : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('EMAIL');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                }}
                            >
                                TAXPAYER IDENTIFICATION NO. <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-group-input-custom"
                                name="tin"
                                value={data.tin}
                                onChange={handleInputChange}
                                required
                                maxLength="14"
                                ref={formRefs['TIN']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('TIN') ? 'red' : ''
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('TIN');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                }}
                            >
                                PROVINCE<span>*</span>
                            </Form.Label>
                            <Select
                                name="province"
                                options={address?.province}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={address?.province?.find(
                                    (option) => option.value?.toLowerCase() === data.province?.toLowerCase()
                                )}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: '45px',
                                        boxShadow: 'none',
                                        textTransform: 'uppercase'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        textTransform: 'uppercase'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    filterCity(selectedOption);
                                }}
                                ref={formRefs['PROVINCE']}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                    // textDecoration: address?.matchedCity?.length ? '' : 'line-through'
                                }}
                            >
                                CITY / MUNICIPALITY <span>*</span>
                            </Form.Label>
                            <Select
                                name="city"
                                className={address?.matchedCity?.length ? '' : 'custom-readonly'}
                                options={address?.matchedCity}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={
                                    address?.matchedCity?.find(
                                        (option) => option.value?.toLowerCase() === data.city?.toLowerCase()
                                    ) || null
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: '45px',
                                        boxShadow: 'none',
                                        textTransform: 'uppercase'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        textTransform: 'uppercase'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    filterBarangay(selectedOption);
                                }}
                                ref={formRefs['CITY']}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                    // textDecoration: address?.matchedBarangay?.length ? '' : 'line-through'
                                }}
                            >
                                BARANGAY<span>*</span>
                            </Form.Label>
                            <Select
                                name="barangay"
                                className={address?.matchedBarangay?.length ? '' : 'custom-readonly'}
                                options={address?.matchedBarangay}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={
                                    address?.matchedBarangay?.find(
                                        (option) => option.value?.toLowerCase() === data.barangay?.toLowerCase()
                                    ) || null
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: '45px',
                                        boxShadow: 'none',
                                        textTransform: 'uppercase'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        textTransform: 'uppercase'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    handleInputChange({ target: { name: 'barangay', value: selectedOption.value } });
                                    handleInputChange({ target: { name: 'zipCode', value: selectedOption.bmCode } });
                                }}
                                ref={formRefs['BARANGAY']}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label
                                className="form-group-label"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    color: '#939598'
                                    // textDecoration: address?.matchedBarangay?.length ? '' : 'line-through'
                                }}
                            >
                                ZIP CODE<span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className={`form-group-input-custom custom-readonly`}
                                name="lastName"
                                readOnly
                                value={data.zipCode}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Label
                            className="form-group-label"
                            style={{
                                fontFamily: 'PoppinsRegular',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                color: '#939598'
                            }}
                        >
                            PLAN<span>*</span>
                        </Form.Label>
                        <Form.Select
                            size="sm"
                            name="plan"
                            className="form-group-input-custom"
                            required
                            style={{
                                textTransform: 'uppercase'
                            }}
                            onChange={setSelectedPlans}
                            ref={selectedPlanRef}
                        >
                            <option value="" disabled selected>
                                Select Plan
                            </option>
                            {plans.length &&
                                plans.map((plan) => {
                                    return (
                                        <option value={plan?.id} key={plan?.id}>
                                            {plan.planName} - ₱ {(plan.premAmt ?? 0).toLocaleString('en-US') ?? 0}/Year
                                        </option>
                                    );
                                })}
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mb-3 mt-5">
                    <Col lg={12}>
                        <Form.Label
                            className="form-group-label"
                            style={{
                                fontFamily: 'PoppinsRegular',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                color: '#939598'
                            }}
                        >
                            I want automatic yearly renewal of my coverage and charging of insurance premiums.
                            <span>*</span>
                        </Form.Label>
                    </Col>
                    <Col lg={4}>
                        <Form.Select
                            size="sm"
                            name="auto-renew"
                            className="form-group-input-custom"
                            required
                            style={{
                                textTransform: 'uppercase'
                            }}
                            onChange={setSelectedType}
                        >
                            {[
                                { name: 'Yes', value: 1 },
                                { name: 'No', value: 0 }
                            ].map((type, index) => {
                                return (
                                    <option
                                        value={type?.value}
                                        {...(index === 0 && { selected: true })}
                                        key={`type-${index}`}
                                    >
                                        {type?.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Col>
                </Row>
                <ClientPromtText>
                    <Form.Group controlId="formCheckbox">
                        <Form.Check
                            className="checkbox"
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            checked={data.agreed}
                            label={
                                <p>
                                    I have read, understood and agree to the{' '}
                                    <u>
                                        <a
                                            href="https://bpims.com/wp-content/uploads/2023/10/BPIMS_Stakeholder_Charter.pdf "
                                            target="bpims"
                                            style={{ textDecoration: 'underline', color: 'inherit' }}
                                        >
                                            BPI MS Customer Charter
                                        </a>
                                    </u>{' '}
                                    ,{' '}
                                    <u>
                                        <a
                                            href="https://bpims.com/wp-content/uploads/2024/02/PCI-Online-Registration-Terms-of-Use-Cleared-with-Automatic-Renewal.pdf"
                                            target="bpims"
                                            style={{ textDecoration: 'underline', color: 'inherit' }}
                                        >
                                            Terms of Use
                                        </a>
                                    </u>{' '}
                                    of this microsite the,{' '}
                                    <u>
                                        <a
                                            href="https://bpims.com/privacy-policy/"
                                            target="bpims"
                                            style={{ textDecoration: 'underline', color: 'inherit' }}
                                        >
                                            BPI MS Privacy Policy
                                        </a>
                                    </u>{' '}
                                    and the{' '}
                                    <u>
                                        <a
                                            href="https://bpims.com/wp-content/uploads/2024/03/PERSONAL-CYBER_TCs-11Oct2023.pdf"
                                            target="bpims"
                                            style={{ textDecoration: 'underline', color: 'inherit' }}
                                        >
                                            Terms and Conditions
                                        </a>
                                    </u>{' '}
                                    of my chosen plan, issued and underwritten by BPI/MS Insurance Corporation for BPI
                                    Debit Mastercard®.
                                </p>
                            }
                        />
                    </Form.Group>
                    {data.autoRenew && (
                        <Form.Group controlId="recurringConsent">
                            <Form.Check
                                className="checkbox"
                                type="checkbox"
                                onChange={handleRecurringCheckboxChange}
                                checked={data.recurring}
                                label={
                                    <p>
                                        I confirm and allow BPI MS to automatically renew my chosen Personal Cyber
                                        Insurance Plan yearly upon its expiry and automatically charge my BPI Debit
                                        Mastercard® yearly for the corresponding annual insurance premium. I understand
                                        and agree that if I want to cancel the yearly automatic charging on my BPI Debit
                                        Mastercard® and automatic renewal of my Personal Cyber Insurance coverage, I
                                        will need to contact BPI MS at{' '}
                                        <a
                                            href="tel:+63288409000"
                                            style={{ textDecoration: 'underline', color: 'inherit' }}
                                        >
                                            (02) 8840-9000
                                        </a>{' '}
                                        or send an email at{' '}
                                        <a
                                            href="mailto:helpline@bpims.com"
                                            style={{ textDecoration: 'underline', color: 'inherit' }}
                                        >
                                            helpline@bpims.com
                                        </a>{' '}
                                        prior to the expiry date of my Personal Cyber Insurance coverage.
                                    </p>
                                }
                            />
                        </Form.Group>
                    )}

                    <p
                        style={{
                            color: '#b41419',
                            fontFamily: 'PoppinsRegular',
                            fontStyle: 'italic',
                            fontWeight: 500,
                            paddingLeft: '23px'
                        }}
                    >
                        Before proceeding to payment, please ensure that the e-commerce access of your BPI Debit
                        Mastercard® is turned ON via Card Control in the BPI App or online to avoid payment issues. You
                        may tap{' '}
                        <a
                            href="https://youtu.be/jSiIpGEGylM?si=jqx_q6wBPA6mkbTv"
                            target="bpims"
                            style={{ textDecoration: 'underline', color: 'inherit' }}
                        >
                            here
                        </a>{' '}
                        to know how.
                    </p>
                </ClientPromtText>

                <div className="d-grid gap-2 justify-content-center mt-5">
                    <Button
                        className="btn-submit"
                        variant=""
                        type="button"
                        style={{
                            backgroundColor: '#ffffff',
                            maxWidth: 'fit-content',
                            padding: '5px 80px',
                            borderRadius: '3px',
                            fontFamily: 'PoppinsSemiBold',
                            fontWeight: 500,
                            fontSize: '16px',
                            color: '#b41419',
                            border: 'solid 2px #b41419',
                            textAlign: 'center'
                        }}
                        disabled={formLoading || !data.agreed || (data.autoRenew && !data.recurring) || !plan}
                        onClick={handleFormSubmit}
                    >
                        PAY NOW
                        {/* {!formLoading ? (
                            'Pay'
                        ) : (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        )} */}
                    </Button>
                </div>
            </StyledForm>
            {/* <ErrorModal
                show={errors.length}
                handleClose={() => setErrors(() => [])}
                errorMessage={`Required field or invalid format: ${errors.join(', ')}`}
            /> */}

            <ErrorModal
                show={errors.length > 0}
                handleClose={() => setErrors(() => [])}
                errorMessage={
                    errors.length
                        ? `<ul>${errors
                              .map(
                                  (field) =>
                                      `<li>${errorMessages[field] || `Required field or invalid format: ${field}`}</li>`
                              )
                              .join('')}</ul>`
                        : ''
                }
            />

            <ConfirmationModal
                show={form}
                handleClose={() => setForm()}
                plan={planObject}
                handleSubmit={handleSubmitConfirm}
            />
        </StyledContainer>
    );
}
