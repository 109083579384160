import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

const StyledContainer = styled(Container)`
    &&& {
        background-color: #e9e5e2 !important;
        color: #303030;
    }
`;

const StyledStack = styled(Stack)`
    &&& {
        font-family: 'PoppinsRegular';
        text-align: center;
        padding: 0% 5%;
    }
`;

const StyledParagraph = styled.p`
    &&& {
        font-size: 16px;
        font-family: 'PoppinsRegular';
        margin: 0;
    }
`;

const StyledButton = styled.button`
    &&& {
        max-width: fit-content;
        margin: 50px auto;
        transition: all 0.2s ease-in 0s;
        background-color: #ffffff;
        max-width: fit-content;
        padding: 5px 80px;
        border-radius: 3px;
        font-family: PoppinsSemiBold;
        font-weight: 500;
        font-size: 16px;
        color: #b41419;
        border: solid 2px #b41419;
        text-align: center;
    }
`;

export default function Claim() {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <StyledContainer fluid id="contact-us">
            <Row>
                <Col lg={12} className="justify-content-center text-center">
                    <StyledStack className="">
                        <h3
                            style={{
                                fontSize: '36px',
                                margin: '50px',
                                fontWeight: '700',
                                fontFamily: 'PoppinsSemiBold'
                            }}
                        >
                            File a Claim
                        </h3>
                        <StyledParagraph>For inquiries, please call</StyledParagraph>
                        <StyledParagraph>
                            our Personal Cyber Customer Hotline at {width < 520 && <br />} (+632) 8840-9444
                        </StyledParagraph>
                        <StyledParagraph>
                            or visit{' '}
                            <a style={{ color: 'inherit' }} href="https://bpims.mycybercentre.com/en/" target="bpims">
                                https://bpims.mycybercentre.com/en/
                            </a>{' '}
                            for more information.
                        </StyledParagraph>
                        <StyledParagraph>
                            For claims status, please email{' '}
                            <a style={{ color: 'inherit' }} href="mailto:bpimsclaims@bpims.com">
                                bpimsclaims@bpims.com
                            </a>
                        </StyledParagraph>
                        <StyledButton onClick={() => window.open('tel:+63288409444')}>CALL HOTLINE</StyledButton>
                    </StyledStack>
                </Col>
            </Row>
        </StyledContainer>
    );
}
