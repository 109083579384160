import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

const MainContainer = styled(Container)`
    &&& {
    }
`;

const BackgroundGradientRotated = styled.div`
    position: absolute;
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, #589aa8 0%, #2f277d 50%);
    z-index: -1001;
    min-height: 1000px;
    top: ${(props) => (props.stage ? '-5%' : '-2%')};
    left: -15%;
    overflow: hidden;

    @media (max-width: 999px) {
    }

    @media only screen and (max-width: 360px) {
        min-height: 1100px;
    }
`;

const LandingContainer = styled(Container)`
    &&& {
    }
    margin-left: 10%;

    @media only screen and (max-width: 575px) {
        margin-left: 5%;
    }

    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
    }

    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 992px) {
    }
`;

const Title = styled.h1`
    color: #fff;
    font-family: MontserratBlack;
    font-size: 30px;
    text-transform: uppercase;

    @media only screen and (min-width: 300px) {
        max-width: 90%;
        font-size: 25px;
    }

    @media only screen and (min-width: 400px) and (max-width: 500px) {
        max-width: 100%;
        font-size: 25px;
    }

    @media only screen and (min-width: 501px) and (max-width: 999px) {
        max-width: 80%;
    }
`;

const Description = styled.p`
    color: #fff;
    font-size: 16px;
    font-family: MontserratMedium;
    padding-right: 4rem;

    @media only screen and (max-width: 500px) {
        max-width: 100%;
    }

    @media only screen and (min-width: 501px) and (max-width: 999px) {
        max-width: 80%;
    }
`;

const Button = styled.button`
    background: #fff;
    color: #b41419;
    border: none;
    padding: 10px 40px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    font-family: PoppinsRegular;
`;

const LandingImageDesktop = styled(Image)`
    position: absolute;
    right: 10px;
    bottom: 5%;
    width: 100vh;

    @media (max-width: 1199px) {
        display: none !important;
    }

    @media (min-width: 1199px) {
        right: -100px;
    }

    @media (min-width: 800px) and (max-width: 980px) {
        // right: -100px;
    }

    @media only screen and (min-width: 1300px) and (max-width: 1500px) {
        // width: 50%;
    }
`;

const LandingImageMobile = styled(Image)`
    position: absolute;
    right: -20px;
    // bottom: -2%;

    @media (min-width: 1300px) {
        display: none !important;
    }

    @media only screen and (max-width: 575.98px) {
        width: 80%;
    }

    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        width: 80%;
    }

    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        width: 60%;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        width: 60%;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        width: 50%;
    }

    @media only screen and (min-width: 1401px) {
        width: 40%;
    }
`;

export default function LandingSuccess() {
    return (
        <MainContainer fluid>
            <BackgroundGradientRotated>
                <LandingImageDesktop src={require('../assets/images/status/success.png')} />
                {/* <LandingImageMobile src={require('../assets/images/status/success.png')} /> */}
            </BackgroundGradientRotated>
            <Row fluid>
                <LandingContainer>
                    <Col xl={6} lg={7} md={12} sm={12} className="justify-content-center p-0">
                        <Row fluid className="pb-2 mt-5">
                            <Col>
                                <Title>Congratulations!</Title>
                            </Col>
                        </Row>
                        <Row fluid>
                            <Col>
                                <Description>
                                    Your transaction is successful and you now enjoy additional protection on your BPI
                                    Debit Mastercard®.
                                </Description>
                            </Col>
                        </Row>
                        <Row fluid>
                            <Col>
                                <Description>
                                    You will receive a copy of your Confirmation of Insurance Coverage through the email
                                    you have provided.
                                </Description>
                            </Col>
                        </Row>
                        <Row fluid className="pb-2 mt-5">
                            <Col>
                                <Title>BPI Cards</Title>
                            </Col>
                        </Row>
                        <Row fluid>
                            <Col>
                                <Description>Learn more about BPI's card products and solutions.</Description>
                            </Col>
                        </Row>

                        <Row fluid>
                            <Col>
                                <Button
                                    onClick={() =>
                                        window.open(
                                            'https://www.bpi.com.ph/personal/cards/debit-cards/debit-mastercard',
                                            'bpims'
                                        )
                                    }
                                >
                                    LEARN MORE
                                </Button>
                            </Col>
                        </Row>

                        <Row fluid className="pb-2 mt-5">
                            <Col>
                                <Title>Shield those that you value via BPI MS Express Protect</Title>
                            </Col>
                        </Row>
                        <Row fluid>
                            <Col>
                                <Description>
                                    Protect your dreams and investments by insuring your car, home, family, and health.
                                    Visit BPI MS Express Protect now.
                                </Description>
                            </Col>
                        </Row>

                        <Row fluid>
                            <Col>
                                <Button onClick={() => window.open('https://eprotect.bpims.com/', 'bpims')}>
                                    LEARN MORE
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </LandingContainer>
            </Row>
        </MainContainer>
    );
}
