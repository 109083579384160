import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

const StyledContainer = styled(Container)`
    &&& {
        background: ${(props) => (props.stage === 'f' ? '' : 'white')};
    }
`;

const StyledStack = styled(Stack)`
    &&& {
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        font-family: 'PoppinsRegular';
        text-align: center;
    }

    @media (max-width: 460px) {
        padding: 0 15px;
    }
`;

const StyledHr = styled.hr`
    &&& {
        width: 80%;
        align-self: center;
    }
`;

const StyledImage = styled(Image)`
    &&& {
        object-fit: contain;
    }

    @media (max-width: 500px) {
        max-width: 100px; /* Adjust this value as needed */
    }
`;

const ICParagraphText = styled.p`
    color: #a7a9ac;
    font-weight: 500;
    font-size: 12px;
    font-family: 'PoppinsRegular';
    text-align: center;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Adjust padding as needed */

    @media (max-width: 500px) {
        padding: 5px; /* Adjust padding as needed */
    }
`;

export default function Footer({ stage }) {
    return (
        <StyledContainer fluid stage={stage}>
            <Row fluid>
                <Col lg={12} className="justify-content-center">
                    <StyledStack className="text-center mb-5">
                        {/* <StyledImage src={require('../assets/images/joint-logo-new.png')} fluid className="mx-auto py-4" /> */}
                        <Row className="justify-content-center">
                            <Col xs="auto" className="p-3">
                                <ImageContainer>
                                    <a href="https://www.bpi.com.ph" target="_blank" rel="noopener noreferrer">
                                        <StyledImage
                                            src={require('../assets/images/bpilogo.png')}
                                            style={{ width: '100%', maxWidth: '150px' }}
                                            fluid
                                            className=""
                                        />
                                    </a>
                                </ImageContainer>
                            </Col>
                            <Col xs="auto" className="p-3">
                                <ImageContainer>
                                    <a href="https://bpims.com/" target="_blank" rel="noopener noreferrer">
                                        <StyledImage
                                            src={require('../assets/images/bpims-logo.png')}
                                            style={{ width: '100%', maxWidth: '170px', paddingTop: '18px' }}
                                            fluid
                                            className=""
                                        />
                                    </a>
                                </ImageContainer>
                            </Col>
                        </Row>
                        {/* <StyledHr /> */}
                        <p className="m-0 p-0">Powered by BPI/MS Insurance Corporation, a joint venture between the</p>
                        <p className="m-0 p-0 mb-3">
                            Bank of the Philippine Islands and Mitsui Sumitomo Insurance of Japan.
                        </p>
                        <p className="m-0 p-0" style={{ fontWeight: 'bold' }}>
                            Policy terms and conditions apply.
                        </p>
                        <p className="m-0 p-0">
                            BPI/MS Insurance Corporation's products are non-life insurance products and are not deposit
                        </p>
                        <p className="m-0 p-0">
                            products of Bank of the Philippine Islands. These products are not covered by the Philippine
                            Deposit
                        </p>
                        <p className="m-0 p-0">Insurance Corporation.</p>
                        <br />
                        <p className="m-0 p-0">
                            For concerns, you may call BPI MS at (02) 8840-9000 or send an e-mail to helpline@bpims.com.
                        </p>
                        <p className="m-0 p-0">Our representatives will be glad to assist you.</p>
                        <br />
                        <p className="m-0 p-0">
                            BPI/MS Insurance Corporation is regulated by the Insurance Commission (IC).
                        </p>
                        <p className="mb-5 p-0">For more details, visit https://www.insurance.gov.ph.</p>

                        <ICParagraphText className="m-0 p-0">
                            IC Public Assistance Office contact details:
                        </ICParagraphText>
                        <ICParagraphText className="m-0 p-0">Landline: (02) 8523-8461 loc. 103/127</ICParagraphText>
                        <ICParagraphText className="m-0 p-0">SMS: 09171160007 or 09999930637</ICParagraphText>
                        <ICParagraphText className="m-0 p-0">E-mail: publicassistance@insurance.gov.ph</ICParagraphText>
                        <ICParagraphText className="m-0 p-0">
                            Offices: https://www.insurance.gov.ph/contact-us/
                        </ICParagraphText>
                        {/* <StyledHr /> */}
                    </StyledStack>
                </Col>
            </Row>
        </StyledContainer>
    );
}
