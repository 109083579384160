const getPlans = async () => {
    try {
        const url = window.location.href;
        const [, stage, , flag] = url.split('.');

        const response = await fetch(
            `https://partners-api.${flag ? `${stage}.` : ''}bpimsapps.com/data-get/geo`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    tables: ['province', 'city', 'barangay']
                })
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('getAddress api error', error);
        throw error;
    }
};

export default getPlans;
