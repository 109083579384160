import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Stack } from 'react-bootstrap';
import numeral from 'numeral';
import styled from 'styled-components';

const SubmitButton = styled(Button)`
    // background: linear-gradient(to right, #b41419, #ec4d51);
    // color: white;
    border: none;
    padding: 10px 60px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`;

const CancelButton = styled(Button)`
    // background: linear-gradient(to right, #b41419, #ec4d51);
    // color: white;
    border: none;
    padding: 10px 60px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`;

const ConfirmationModal = ({ show, handleClose, plan, handleSubmit }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title style={{ fontFamily: 'PoppinsRegular' }}>Premium Breakdown</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '3% 8%' }}>
                <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'PoppinsRegular' }}
                >
                    <p>Basic Premium</p>
                    <p>{numeral(plan?.basePrem ?? 0).format('0,0.00')}</p>
                </Stack>
                <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'PoppinsRegular' }}
                >
                    <p>Documentary Stamp Tax</p>
                    <p>{numeral(plan?.dstAmt ?? 0).format('0,0.00')}</p>
                </Stack>
                <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'PoppinsRegular' }}
                >
                    <p>Value Added Tax</p>
                    <p>{numeral(plan?.vatAmt ?? 0).format('0,0.00')}</p>
                </Stack>
                <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'PoppinsRegular' }}
                >
                    <p>Local Gov't. Tax</p>
                    <p>{numeral(plan?.lgtAmt ?? 0).format('0,0.00')}</p>
                </Stack>
                <hr />
                <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'PoppinsRegular' }}
                >
                    <p>Total Amount Due</p>
                    <p>₱ {numeral(plan?.premAmt ?? 0).format('0,0.00')}</p>
                </Stack>
                <label className="form-check-label" style={{ width: '100%' }}>
                    <p
                        className="d-grid gap-2"
                        style={{ fontSize: '12px', fontWeight: 'bold', fontStyle: 'italic', margin: 0 }}
                    >
                        *Please make sure to turn on the e-commerce access of your BPI Debit Mastercard® to proceed with
                        the transaction.
                    </p>
                </label>
            </Modal.Body>
            <Modal.Footer style={{ border: 'none' }}>
                <SubmitButton
                    variant="secondary"
                    onClick={handleClose}
                    style={{ border: 'none', fontFamily: 'PoppinsRegular', padding: '10px 15px', marginRight: '10px' }}
                >
                    Cancel
                </SubmitButton>
                <CancelButton
                    variant="primary"
                    onClick={handleSubmit}
                    style={{
                        background: '#f2d841',
                        color: 'black',
                        border: 'none',
                        fontFamily: 'PoppinsRegular',
                        padding: '10px 15px'
                    }}
                >
                    Confirm
                </CancelButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
