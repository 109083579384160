import React from 'react';
import styled from 'styled-components';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

const StyledContainer = styled(Container)`
    &&& {
        padding: 0 17% !important;
    }
`;

const StyledH1 = styled.h1`
    font-family: 'MontserratBold';
    font-size: 36px;
    font-weight: 700;
`;

const StyledH2 = styled.h2`
    font-family: 'PoppinsSemiBold';
    font-size: 30px;
    font-weight: 500;
`;

const StyledH3 = styled.h3`
    font-family: 'PoppinsRegular';
    font-size: 20px;
    font-weight: 100;
    color: #939598;
`;

const StyledCard = styled(Card)`
    &&& {
        height: 100%;
        border-radius: 16px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }

    padding: 2rem;

    @media (max-width: 767px) {
        padding: 1rem;
    }
`;

const StyledButton1 = styled.button`
    border-radius: 32px;
    background: rgb(242, 216, 65);
    transition: all 0.2s ease-in 0s;
    padding: 5px 20px;
    max-width: fit-content;
    margin: 0 auto;
    border: none;
`;

const StyledCol = styled(Col)`
    &&& {
        // display: flex;
        justify-content: center;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 10px;
    }

    @media (max-width: 767px) {
        margin-bottom: 10px;
    }
`;

const StyledAnchor = styled.a`
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const CardRow = styled(Row)`
    &&& {
    }
    margin-bottom: 5%;

    @media (max-width: 767px) {
        margin-bottom: 10%;
    }
`;

const CardCol = styled(Col)`
    &&& {
    }

    @media (max-width: 767px) {
        margin-bottom: 10%;
    }
`;

const StyledPlanLink = styled.a`
    transition: all 0.2s ease-in 0s;
    padding: 5px 20px;
    border-width: 0;
    max-width: fit-content;
    margin: 0 auto;
    font-weight: 700;
    color: #b41419;
    cursor: pointer;
    font-family: 'PoppinsSemiBold';
`;

const StyledButton = styled.button`
    &&& {
        max-width: fit-content;
        margin: 10px auto;
        transition: all 0.2s ease-in 0s;
        background-color: #ffffff;
        max-width: fit-content;
        padding: 5px 80px;
        border-radius: 3px;
        font-family: PoppinsSemiBold;
        font-weight: 500;
        font-size: 16px;
        color: #b41419;
        border: solid 2px #b41419;
        text-align: center;
    }
`;

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
}

const images = importAll(require.context('../assets/images/benefits', false, /\.(png|jpe?g|svg)$/));

export default function Benefits({ Slider, benefits, settings }) {
    const sliderSettings = {
        ...settings,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 8000,
        customPaging: (i) => (
            <div
                style={{
                    height: '13px',
                    borderRadius: '9999px',
                    position: 'relative'
                    // marginTop: '50px'
                }}
            ></div>
        )
    };

    return (
        <StyledContainer fluid className="p-0 mt-0">
            <Row fluid>
                <Col lg={12} className="justify-content-center">
                    <Row fluid className="mb-3">
                        <Col>
                            <StyledH1>Benefits across all plans:</StyledH1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5">
                        <StyledCol sm={12} md={12} lg={12}>
                            <StyledCard className="">
                                <Card.Body>
                                    <Slider {...sliderSettings}>
                                        {benefits &&
                                            benefits.map((benefit, index) => {
                                                return (
                                                    <div key={benefit.id}>
                                                        <CardRow>
                                                            <CardCol sm={12} lg={3} className="text-center">
                                                                <Image
                                                                    src={images[benefit.icon]}
                                                                    style={{
                                                                        maxHeight: '100px',
                                                                        maxWidth: '100px',
                                                                        width: '120%',
                                                                        height: 'auto'
                                                                    }}
                                                                    className="mx-auto"
                                                                />
                                                            </CardCol>
                                                            <Col sm={12} lg={9}>
                                                                <StyledH2>{benefit.title}</StyledH2>
                                                                <StyledH3>{benefit.content}</StyledH3>
                                                            </Col>
                                                        </CardRow>
                                                    </div>
                                                );
                                            })}
                                    </Slider>
                                </Card.Body>
                            </StyledCard>
                        </StyledCol>
                    </Row>
                    {/* <Row fluid className="justify-content-center text-center">
                            <StyledButton
                                onClick={() => window.open('https://bpims.com/bpi-debit-insurance/', 'bpims')}
                            >
                                LEARN MORE
                            </StyledButton>
                        </Row> */}
                    <Row fluid className="justify-content-center text-center">
                        <StyledButton
                            onClick={() =>
                                window.open(
                                    'https://www.bpi.com.ph/personal/cards/debit-cards/debit-mastercard/personal-cyber-insurance',
                                    'bpims'
                                )
                            }
                        >
                            LEARN MORE
                        </StyledButton>
                    </Row>
                </Col>
            </Row>
        </StyledContainer>
    );
}
