import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';

import AppNavbar from './components/AppNavBar';
import Landing from './components/Landing';
import Benefits from './components/Benefits';
import Plans from './components/Plans';
import Form from './components/Form';
import AboutUs from './components/AboutUs';
import Claim from './components/Claim';
import Footer from './components/Footer';
import LandingSuccess from './components/LandingSuccess';
import LandingFailed from './components/LandingFailed';
// import Trademark from './components/Trademark';
import Image from 'react-bootstrap/Image';

import submitForm from './service/submitForm';
import getTransactionStatus from './service/getTransactionStatus';

import getPlans from './service/getPlans';
import getAddress from './service/getAddress';

import './App.css';

import { banners, reactSlickSettings, benefits } from './data';
import Slider from 'react-slick';

const MainContainer = styled(Container)`
    &&& {
        position: relative;
        overflow-x: clip;
    }
`;

const BackgroundGradientRotated = styled.div`
    position: absolute;
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, #589aa8 0%, #2f277d 50%);
    z-index: -1001;
    min-height: ${(props) => (props.stage ? '1000px' : '700px')};
    border-bottom-right-radius: ${(props) => (props.stage === 's' ? '0px' : '250px')};
    top: ${(props) => (props.stage ? '-5%' : '-2%')};
    left: -15%;
    rotate: ${(props) => (props.stage === 's' ? '0deg' : '3deg')};
    overflow: hidden;

    @media (max-width: 999px) {
        rotate: ${(props) => (props.stage === 's' ? '0deg' : '2deg')};
        border-bottom-right-radius: ${(props) => (props.stage === 's' ? '0px' : '80px')};
    }

    @media (max-width: 991px) {
        height: ${(props) => (props.isNavbarExpanded ? '900px' : '700px')};
    }
`;

const Loading = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
`;

const PlanAndBenefitsDiv = styled.div`
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const LandingImageDesktop = styled(Image)`
    position: absolute;
    right: 10px;
    bottom: -4%;
    width: 100vh;

    @media (max-width: 1299px) {
        display: none !important;
    }

    @media only screen and (min-width: 1300px) and (max-width: 1500px) {
        width: 50%;
    }
`;

const LandingImageMobile = styled(Image)`
    position: absolute;
    right: -30px;
    bottom: -2%;

    @media (min-width: 1300px) {
        display: none !important;
    }

    @media only screen and (max-width: 575.98px) {
        width: 80%;
        bottom: -5%;
        right: -20px;
    }

    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        width: 80%;
        bottom: -10%;
    }

    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        width: 60%;
        right: -40px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        width: 60%;
        bottom: -10%;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        width: 50%;
    }

    @media only screen and (min-width: 1401px) {
        width: 40%;
    }
`;

const StyledFormContainer = styled(Container)`
    // margin-top: 15%;

    // @media only screen (max-width: 766px) {
    //     margin-top: ${(props) => (props.stage === 's' ? '0%' : '120%')};
    // }

    // @media only screen and (min-height: 600px) and (max-height: 1000px) and (max-width: 767px) {
    //     margin-top: ${(props) => (props.stage === 's' ? '0%' : '50%')};
    // }

    // @media only screen and (min-width: 768px) and (max-width: 998px) {
    //     margin-top: ${(props) => (props.stage === 's' ? '0%' : '30%')};
    // }

    // @media only screen and (min-width: 999px) and (max-width: 1300px) {
    //     margin-top: ${(props) => (props.stage === 's' ? '0%' : '20%')};
    // }
`;

const SliderArrowDiv = styled.div`
    display: block;
    position: absolute;
    padding-top: 20px;
    z-index: 100;
    top: 45%;
    opacity: 40%;

    @media (max-width: 999px) {
        display: none !important;
    }
`;

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
}

const images = importAll(require.context('./assets/images/banners', false, /\.(png|jpe?g|svg)$/));

function App() {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamValue = queryParams.get('stage');

    const [loading, setLoading] = React.useState(false);
    const [loadingPlans, setLoadingPlans] = React.useState(false);
    const [allowReload, setAllowReload] = React.useState(false);
    const [selectedPlan, setSelectedPlan] = React.useState({});
    const [form, setForm] = React.useState({
        autoRenew: true
    });
    const [plans, setPlans] = React.useState([]);
    const [bannerImage, setBannerImage] = React.useState({});
    const [address, setAddress] = React.useState(null);
    const [loadingForm, setLoadingForm] = React.useState(false);
    const sliderRef = React.useRef();
    const [isNavbarExpanded, setNavbarExpanded] = React.useState(false);

    const settings = {
        ...reactSlickSettings,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 15000,
        customPaging: (i) => (
            <div
                style={{
                    height: '13px',
                    borderRadius: '9999px',
                    position: 'relative'
                    // display: 'none'
                }}
            ></div>
        ),
        beforeChange: (current, next) => {
            setBannerImage({
                bannerDesktop: `b${next + 1}.png`,
                bannerMobile: `b${next + 1}-mobile.png`
            });
        },
        prevArrow: <PrevArrow slider={sliderRef} />,
        nextArrow: <NextArrow slider={sliderRef} />
    };

    React.useEffect(() => {
        if (queryParamValue && queryParamValue != 's' && queryParamValue != 'f') {
            setLoading(true);
            handleTransactionStatus(queryParamValue, () =>
                handleTransactionStatus(queryParamValue, () => {
                    setAllowReload(() => true);
                    handleTransactionStatus(queryParamValue, () =>
                        handleTransactionStatus(queryParamValue, () =>
                            handleTransactionStatus(queryParamValue, () => {
                                setTimeout(() => window.location.reload(), 5000);
                            })
                        )
                    );
                })
            );
        }

        if (queryParamValue === 'f') {
            document.body.style.backgroundColor = 'white !important';
        } else {
        }
    }, []);

    React.useEffect(() => {
        if (selectedPlan.id) {
            const formSection = document.getElementById('form-section');
            // if (formSection) formSection.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedPlan]);

    React.useEffect(() => {
        handlePlans();
        handleAddress();
        window.scrollTo(0, 0);

        setTimeout(() => {
            const formSection = document.getElementById('form-section');
            if (formSection) formSection.scrollIntoView({ behavior: 'smooth' });
        }, 2000);
    }, []);

    const handlePlans = async () => {
        if (loadingPlans) return;

        setLoadingPlans(true);
        setPlans([]);
        try {
            const _plans = await getPlans();
            const { plan } = _plans.data;
            setPlans(plan.slice());
        } catch (error) {
            console.error('Error fetching plans:', error);
        } finally {
            setLoadingPlans(false);
            const url = new URL(window.location.href);
            const hash = url.hash;
            if (hash && hash === '#form') {
                const formElement = document.getElementById('form-section');
                if (formElement) {
                    window.scrollTo(0, 0);
                    formElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    const handleAddress = async () => {
        try {
            setLoadingForm(true);
            const _address = await getAddress();
            const { province, city, barangay } = _address;
            console.log('address', _address);

            const mappedProvince = province.map((item) => {
                return { ...item, key: item.provinceName, value: item.provinceName };
            });

            const mappedCity = city.map((item) => {
                return { ...item, key: item.cityName, value: item.cityName };
            });

            const mappedBarangay = barangay.map((item) => {
                return { ...item, key: item.postalDesc, value: item.postalDesc };
            });

            setAddress({
                province: mappedProvince,
                city: mappedCity,
                barangay: mappedBarangay
            });
            setLoadingForm(false);
        } catch (error) {
            console.error('Error fetching address:', error);
            setLoadingForm(false);
        } finally {
            setLoadingForm(false);
        }
    };

    const handleSubmit = async () => {
        if (loading) return;
        document.body.style.overflow = 'hidden';

        setLoading(true);
        try {
            const res = await submitForm({ ...form, planId: selectedPlan?.id });
            const { Location } = res;
            if (Location) {
                window.location.href = Location;
            }
            setSelectedPlan({});
            setForm({});
        } catch (error) {
            console.error('API Call error', error);
        } finally {
            setLoading(false);
            document.body.style.overflow = '';
        }
    };

    const handleTransactionStatus = async (tranxRef, callback = () => {}) => {
        try {
            document.body.style.overflow = 'hidden';
            const res = await getTransactionStatus({ tranxRef });
            const { policyStatus } = res;

            if (['s', 'f'].includes(policyStatus)) window.location.href = `/?stage=${policyStatus}`;
            if (!callback) return;
            callback(); // retry if it fails
        } catch (error) {
            console.error('API handleTransactionStatus Call error', error);
        }
    };

    return (
        <MainContainer className="p-0" fluid>
            {!queryParamValue && (
                <BackgroundGradientRotated stage={queryParamValue} isNavbarExpanded={isNavbarExpanded}>
                    <div>
                        <LandingImageDesktop src={images[bannerImage.bannerDesktop ?? 'b1.png']} />
                        <LandingImageMobile src={images[bannerImage.bannerMobile ?? 'b1-mobile.png']} />
                    </div>
                </BackgroundGradientRotated>
            )}

            {loading && (
                <Loading>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Loading>
            )}

            {allowReload && (
                <Alert
                    variant="warning"
                    style={{
                        zIndex: 5000,
                        position: 'absolute',
                        top: '5%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    It seems your transaction is taking longer than expected. We will retry in a few seconds.
                </Alert>
            )}

            <Row>
                <AppNavbar isNavbarExpanded={isNavbarExpanded} setNavbarExpanded={setNavbarExpanded} />
            </Row>

            <Row className="mb-5">
                {!queryParamValue && (
                    <Slider {...settings} ref={sliderRef}>
                        {banners &&
                            banners.map((banner, index) => {
                                return (
                                    <div key={banner.id}>
                                        <Landing stage={queryParamValue} banner={banner} />
                                    </div>
                                );
                            })}
                    </Slider>
                )}

                {queryParamValue === 's' && <LandingSuccess />}

                {queryParamValue === 'f' && <LandingFailed />}
            </Row>

            <StyledFormContainer fluid className="p-0" stage={queryParamValue}>
                {!queryParamValue && (
                    <Row>
                        <Form
                            data={form}
                            handleData={setForm}
                            handleSubmit={handleSubmit}
                            plan={selectedPlan?.id}
                            planObject={selectedPlan}
                            formLoading={loading}
                            plans={plans}
                            setSelectedPlan={setSelectedPlan}
                            address={address}
                            setAddress={setAddress}
                            loadingForm={loadingForm}
                        />
                    </Row>
                )}

                {!queryParamValue && (
                    <PlanAndBenefitsDiv>
                        <Row className="mb-5">
                            <Plans handlePlanSelection={setSelectedPlan} plans={plans} loading={loadingPlans} />
                        </Row>
                        <Row className="mb-5">
                            <Benefits Slider={Slider} benefits={benefits} settings={reactSlickSettings} />
                        </Row>
                    </PlanAndBenefitsDiv>
                )}

                {queryParamValue !== 'f' && (
                    <Row>
                        <Claim />
                    </Row>
                )}

                {!queryParamValue && (
                    <Row>
                        <AboutUs />
                    </Row>
                )}

                <Row>
                    <Footer stage={queryParamValue} />
                </Row>
            </StyledFormContainer>
        </MainContainer>
    );
}

function PrevArrow(props) {
    const { className, style, onClick, slider } = props;
    return (
        <SliderArrowDiv
            style={{
                ...style,
                paddingLeft: '30px'
            }}
            onClick={onClick}
        >
            <Button
                className=""
                variant=""
                type="button"
                style={{
                    backgroundColor: '#ffffff',
                    borderRadius: '3px',
                    padding: '5px 10px',
                    paddingTop: '2px'
                }}
                onClick={() => slider?.current?.slickPrev()}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#2f277d"
                >
                    <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
                </svg>
            </Button>
        </SliderArrowDiv>
    );
}

function NextArrow(props) {
    const { className, style, onClick, slider } = props;
    return (
        <SliderArrowDiv
            style={{
                ...style,
                paddingRight: '30px',
                right: '10px'
            }}
            onClick={onClick}
        >
            <Button
                className="btn-submit"
                variant=""
                type="button"
                style={{
                    backgroundColor: '#ffffff',
                    borderRadius: '3px',
                    padding: '5px 10px'
                    // paddingTop: '2px'
                }}
                onClick={() => slider?.current?.slickPrev()}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#2f277d"
                >
                    <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
                </svg>
            </Button>
        </SliderArrowDiv>
    );
}

export default App;
