let settings = {
    dots: true,
    infinite: true,
    speed: 100,
    swipeToSlide: true,
    slidesToShow: 1,
    touchThreshold: 30,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    nextArrow: (
        <div
            style={
                {
                    // display: 'none'
                }
            }
        ></div>
    ),
    prevArrow: (
        <div
            style={
                {
                    // display: 'none'
                }
            }
        ></div>
    ),
    appendDots: (dots) => (
        <div>
            <ul>{dots}</ul>
        </div>
    ),
    customPaging: (i) => (
        <div
            style={{
                height: '13px',
                borderRadius: '9999px',
                position: 'relative'
                // marginTop: '50px'
            }}
        ></div>
    )
};

export default settings;
