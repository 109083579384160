import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

const MainContainer = styled(Container)`
    &&& {
    }
`;

const BackgroundGradient = styled.div`
    background: radial-gradient(circle, #589aa8 0%, #2f277d 50%);
    position: absolute;
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1001;
    min-height: 250px;
    top: ${(props) => (props.stage ? '-5%' : '-2%')};
    left: -15%;
    overflow: hidden;
`;

const BackgroundGradientRotated = styled.div`
    position: absolute;
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, #589aa8 0%, #2f277d 50%);
    z-index: -1001;
    min-height: 850px;
    top: ${(props) => (props.stage ? '-5%' : '-2%')};
    left: -15%;
    overflow: hidden;

    border-bottom-right-radius: ${(props) => (props.stage === 's' ? '0px' : '250px')};
    rotate: ${(props) => (props.stage === 's' ? '0deg' : '4deg')};

    @media (max-width: 999px) {
        rotate: ${(props) => (props.stage === 's' ? '0deg' : '3deg')};
        border-bottom-right-radius: ${(props) => (props.stage === 's' ? '0px' : '80px')};
    }

    @media only screen and (max-width: 360px) {
        min-height: 1100px;
    }
`;

const LandingContainer = styled(Container)`
    &&& {
    }
    margin-left: 10%;

    @media only screen and (max-width: 575px) {
        margin-left: 5%;
    }

    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
    }

    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 992px) {
    }
`;

const Title = styled.h1`
    color: #fff;
    font-family: MontserratBlack;
    font-size: 30px;
    text-transform: uppercase;

    @media only screen and (min-width: 300px) {
        max-width: 80%;
        font-size: 25px;
    }

    @media only screen and (min-width: 400px) and (max-width: 500px) {
        max-width: 100%;
        font-size: 25px;
    }

    @media only screen and (min-width: 501px) and (max-width: 999px) {
        max-width: 80%;
    }
`;

const Description = styled.p`
    color: #fff;
    font-size: 16px;
    font-family: MontserratMedium;
    padding-right: 4rem;

    @media only screen and (max-width: 500px) {
        max-width: 100%;
    }

    @media only screen and (min-width: 501px) and (max-width: 999px) {
        max-width: 80%;
    }
`;

const Button = styled.button`
    background: #fff;
    color: #b41419;
    border: none;
    padding: 10px 40px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    font-family: PoppinsRegular;
`;

const LandingImageDesktop = styled(Image)`
    position: absolute;
    right: 70px;
    bottom: -20%;
    width: 90%;

    @media (max-width: 1199px) {
        display: none !important;
    }

    @media (min-width: 1199px) {
        width: 70%;
    }

    @media (min-width: 800px) and (max-width: 980px) {
        width: 70%;
    }

    @media only screen and (min-width: 1300px) and (max-width: 1500px) {
        width: 70%;
    }
`;

const LandingImageMobile = styled(Image)`
    position: absolute;
    right: -20px;
    // bottom: -2%;

    @media (min-width: 1300px) {
        display: none !important;
    }

    @media only screen and (max-width: 575.98px) {
        width: 80%;
    }

    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        width: 80%;
    }

    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        width: 60%;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        width: 60%;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        width: 50%;
    }

    @media only screen and (min-width: 1401px) {
        width: 40%;
    }
`;

const Separator = styled(Row)`
    margin-bottom: 10%;
`;

export default function LandingSuccess() {
    return (
        <MainContainer fluid>
            <BackgroundGradient />
            <BackgroundGradientRotated>
                <LandingImageDesktop src={require('../assets/images/status/failed.png')} />
                {/* <LandingImageMobile src={require('../assets/images/status/success.png')} /> */}
            </BackgroundGradientRotated>
            <Row fluid>
                <LandingContainer>
                    <Col xl={6} lg={7} md={12} sm={12} className="justify-content-center p-0">
                        <Row fluid className="pb-2 mt-5">
                            <Col>
                                <Title>Sorry, we've not been able to process the transaction.</Title>
                            </Col>
                        </Row>
                        <Row fluid>
                            <Col>
                                <Description>
                                    We were unable to debit your account and so your coverage is not yet active.
                                </Description>
                            </Col>
                        </Row>
                        <Row fluid className="mt-2">
                            <Col>
                                <Description>Don't worry you can try again!</Description>
                            </Col>
                        </Row>
                        <Row fluid className="mt-2">
                            <Col>
                                <Description>
                                    Please ensure that your BPI Debit Mastercard® has sufficient funds and that your
                                    e-commerce access is activated.
                                </Description>
                            </Col>
                        </Row>
                        <Row fluid className="mt-2">
                            <Col>
                                <Description>
                                    Use the button below to learn how to activate the e-commerce access of your BPI
                                    Debit Mastercard®.
                                </Description>
                            </Col>
                        </Row>

                        <Row fluid className="mt-3">
                            <Col>
                                <Button
                                    onClick={() =>
                                        window.open('https://youtu.be/jSiIpGEGylM?si=jqx_q6wBPA6mkbTv', 'bpims')
                                    }
                                >
                                    LEARN MORE
                                </Button>
                            </Col>
                        </Row>

                        <Row fluid style={{ marginTop: '10%' }}>
                            <Col>
                                <Description>
                                    You can also visit{' '}
                                    <a style={{ color: 'inherit' }} href="https://bpi.com.ph" target="bpims">
                                        https://bpi.com.ph
                                    </a>{' '}
                                    to open a savings account that comes with a BPI Debit Mastercard®.
                                </Description>
                            </Col>
                        </Row>
                    </Col>
                </LandingContainer>
            </Row>
            <Separator>
                <Col></Col>
            </Separator>
        </MainContainer>
    );
}
