import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

const MainContainer = styled(Container)`
    &&& {
    }
    position: relative;
    padding-bottom: 20vh;
`;

const LandingContainer = styled(Container)`
    &&& {
    }
    margin-left: 15%;
    // height: 60vh;

    @media only screen and (max-width: 575px) {
        margin-left: 2%;
    }

    /* Small devices (portrait tablets and large phones, 576px and up) */
    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        margin-left: 5%;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        margin-left: 5%;
    }

    @media (max-width: 992px) {
        font-size: 30px;
    }
`;

const Title = styled.h1`
    color: rgba(249, 249, 249, 1);
    font-family: MontserratBlack;
    font-size: 40px;
    text-transform: uppercase;

    @media only screen and (max-width: 575px) {
        font-size: 25px;
    }

    /* Small devices (portrait tablets and large phones, 576px and up) */
    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        font-size: 30px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        font-size: 40px;
    }
`;

const Description = styled.p`
    color: #fff;
    font-size: 16px;
    font-family: MontserratMedium;
    padding-right: 4rem;
`;

const Button = styled.button`
    background: linear-gradient(to right, #b41419, #ec4d51);
    // background: rgba(126, 121, 173);
    color: white;
    border: none;
    padding: 10px 60px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

    @media only screen and (max-width: 575px) {
        padding: 10px 30px;
    }

    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        padding: 10px 30px;
    }

    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        padding: 10px 30px;
    }
`;

export default function Landing({ stage, banner }) {
    return (
        <MainContainer fluid>
            <Row fluid>
                <Col xl={5} lg={7} md={12} sm={12} className="justify-content-center p-0">
                    <LandingContainer>
                        <Col>
                            <Container fluid className="p-0">
                                <Row fluid className="pb-2 mt-5">
                                    <Col>
                                        <Title>{banner.title}</Title>
                                    </Col>
                                </Row>
                                <Row fluid>
                                    <Col xs={12}>
                                        <Description>{banner.subTitle}</Description>
                                    </Col>
                                </Row>
                                <Row fluid>
                                    <Col>
                                        <Button
                                            onClick={() => {
                                                const plansSection = document.getElementById('form-section');
                                                plansSection.scrollIntoView({ behavior: 'smooth' });
                                            }}
                                            style={{ fontFamily: 'PoppinsSemiBold' }}
                                        >
                                            ENROLL NOW
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </LandingContainer>
                </Col>
            </Row>
        </MainContainer>
    );
}
