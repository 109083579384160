const banners = [
    {
        id: 1,
        title: 'Secure your Debit Card against Fraud with Personal Cyber Insurance!',
        subTitle: 'Get reimbursed for fraudulent transfers made with your BPI Debit Mastercard®.',
    },
    {
        id: 2,
        title: 'Insure your Online shopping with personal cyber insurance!',
        subTitle:
            'Enjoy coverage for non-delivery, damage or theft of online purchases made with your BPI Debit Mastercard®.',
    },
    {
        id: 3,
        title: 'Avoid the Expenses of identity theft with personal cyber insurance!',
        subTitle: 'Get reimbursed for expenses you incur when recovering from identity theft, such as legal fees.',
    }
];

export default banners;
