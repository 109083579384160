const benefits = [
    {
        id: 1,
        title: 'Funds Transfer Fraud Protection',
        content: 'Recover the amount of unauthorized transactions made to your debit card.',
        icon: 'ben - 1.png'
    },
    {
        id: 2,
        title: 'Online Purchase Protection',
        content: 'Get reimbursed for damaged or undelivered items that you purchased online using your card.',
        icon: 'ben - 2.png'
    },
    {
        id: 3,
        title: 'Coverage for stolen identity',
        content: 'Get reimbursed for cost of legal fees to clear your name due to use of your information.',
        icon: 'ben - 3.png'
    }
];

export default benefits;
