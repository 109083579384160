import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ErrorModal = ({ show, handleClose, errorMessage }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Unable to submit form</Modal.Title>
            </Modal.Header>
            {/* <Modal.Body>{errorMessage}</Modal.Body> */}
            <Modal.Body><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
