const getTransactionStatus = async (_data) => {
    try {
        const url = window.location.href;
        const [, stage, , flag] = url.split('.');
        const response = await fetch(
            `https://partners-api.${flag ? `${stage}.` : ''}bpimsapps.com/payment/recurring/status`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('getTransactionStatus api error', error);
        throw error;
    }
};

export default getTransactionStatus;
