import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Spinner, Row, Col, Card, Stack, Image } from 'react-bootstrap';

const StyledContainer = styled(Container)`
    margin-top: 5%;
    padding: 0 17% !important;
`;

const StyledH1 = styled.h1`
    font-family: 'MontserratBold';
    font-size: 36px;
    font-weight: 700;
`;

const StyledPlanButton = styled.button`
    border-radius: 32px;
    background: rgb(242, 216, 65);
    transition: all 0.2s ease-in 0s;
    padding: 5px 20px;
    border-width: 0;
    max-width: fit-content;
    margin: 0 auto;
`;

const StyledPlanLink = styled.a`
    transition: all 0.2s ease-in 0s;
    padding: 5px 20px;
    border-width: 0;
    max-width: fit-content;
    margin: 0 auto;
    font-weight: 700;
    color: #b41419;
    cursor: pointer;
    font-family: 'PoppinsSemiBold';
`;

const StyledCol = styled(Col)`
    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 20px;
    }

    @media (max-width: 767px) {
        margin-bottom: 20px;
    }
`;

const Ribbon = styled.div`
    font-weight: bold;
    font-family: 'PoppinsSemiBold';
    font-size: 14px;
    position: absolute;
    top: 12px;
    left: -45px;
    // background-color: #fcb900ff;
    // color: #6742bdff;
    background-color: #71b8b4;
    color: white;
    padding: 5px 50px;
    transform: rotate(330deg);
    z-index: 1;
`;

const StyledPlanCardHeader = styled(Stack)`
    background: ${(props) => {
        if (props.planName === 'Basic') {
            return '#751113';
        } else if (props.planName === 'Prime') {
            return '#f4850d';
        } else if (props.planName === 'Elite') {
            return '#343a84';
        }
    }};
    // background: rgb(103, 67, 189);
    padding: 20px;
`;

const StyledPlanDetails = styled.p`
    font-family: PoppinsRegular;
    font-size: 15px;
    // color: rgba(86, 86, 86, 1);
    text-align: center;
    margin-top: ${(props) => (props.totalAnnual ? '30px' : '10px')};
    margin-left: 30px;
    margin-right: 30px;
    color: #939598;
`;

const StyledUmbrellaIcon = styled(Image)`
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 30%;
    right: -20vw;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const StyledShieldIcon = styled(Image)`
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    width: 20%;
    right: 80vw;

    @media (max-width: 1024px) {
        display: none;
    }
`;

export default function Benefits({ handlePlanSelection, plans, loading }) {
    return (
        <StyledContainer fluid className="p-0" id="plans" style={{ minHeight: loading ? '460px' : 'unset' }}>
            <Row fluid className="mb-3">
                <Col>
                    <StyledH1>Plan Options:</StyledH1>
                </Col>
            </Row>
            <Row fluid className="justify-content-around">
                {loading && <Spinner animation="border" role="status" />}
                {plans.map((plan, index) => (
                    <PlanComponent
                        key={plan.id}
                        plan={plan}
                        handlePlanSelection={handlePlanSelection}
                        isPopular={index === 1}
                    />
                ))}
            </Row>

            <StyledUmbrellaIcon src={require('../assets/images/plans/Umbrella.png')} />
            <StyledShieldIcon src={require('../assets/images/plans/Shield.png')} />
        </StyledContainer>
    );
}

const PlanComponent = ({ plan, handlePlanSelection, isPopular = false }) => {
    const handleSelectPlan = () => {
        handlePlanSelection(plan);
    };

    return (
        <StyledCol sm={12} md={12} lg={4}>
            <Card
                className="text-center"
                style={{
                    height: '100%',
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                    overflow: 'hidden',
                    borderRadius: '16px',
                    backgroundColor: '#f0f0f0',
                    border: 'none'
                }}
            >
                {isPopular && <Ribbon>POPULAR!</Ribbon>}
                <Card.Body style={{ padding: 0 }} className="pb-4">
                    <Stack gap={3}>
                        <StyledPlanCardHeader planName={plan.planName}>
                            <p
                                className="p-0 text-white"
                                style={{
                                    fontFamily: 'PoppinsRegular',
                                    fontSize: '16px',
                                    textTransform: 'uppercase',
                                    marginBottom: '25px'
                                }}
                            >
                                {plan.planName}
                            </p>
                            <Stack
                                direction="horizontal"
                                className="justify-content-center"
                                style={{ fontFamily: 'PoppinsSemiBold' }}
                            >
                                <p
                                    className="p-0 m-0 text-white"
                                    style={{
                                        fontSize: '20px',
                                        alignSelf: 'self-start',
                                        fontFamily: 'MontserratRegular'
                                    }}
                                >
                                    ₱
                                </p>
                                <p
                                    className="p-0 m-0 text-white"
                                    style={{ fontSize: '54px', fontWeight: 700, lineHeight: '54px' }}
                                >
                                    &nbsp;{plan.premAmt.toLocaleString()}
                                </p>
                                <p
                                    className="p-0 m-0 text-white"
                                    style={{
                                        fontFamily: 'PoppinsRegular',
                                        fontSize: '16px',
                                        color: '#fff',
                                        alignSelf: 'self-end'
                                    }}
                                >
                                    /year
                                </p>
                            </Stack>
                        </StyledPlanCardHeader>
                        <Stack>
                            <Stack direction="horizontal" className="justify-content-center">
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        // color: 'rgb(111, 111, 111)',
                                        fontFamily: 'PoppinsRegular',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        alignSelf: 'self-start',
                                        color: '#939598'
                                    }}
                                >
                                    Up to
                                </p>
                            </Stack>
                            <Stack direction="horizontal" className="justify-content-center">
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        // color: 'rgb(111, 111, 111)',
                                        fontFamily: 'MontserratRegular',
                                        color: '#939598',
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        alignSelf: 'self-start'
                                    }}
                                >
                                    ₱
                                </p>
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        // color: 'rgb(111, 111, 111)',
                                        fontFamily: 'PoppinsSemiBold',
                                        color: '#939598',
                                        fontSize: '44px',
                                        fontWeight: 700,
                                        lineHeight: '44px'
                                    }}
                                >
                                    &nbsp;{(plan.covPerInc ?? 0).toLocaleString()}
                                </p>
                            </Stack>
                            <StyledPlanDetails>
                                Maximum reimbursement per occurrence of covered cyber event
                            </StyledPlanDetails>
                        </Stack>
                        <Stack>
                            <Stack direction="horizontal" className="justify-content-center">
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        // color: 'rgb(111, 111, 111)',
                                        fontFamily: 'MontserratRegular',
                                        color: '#939598',
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        alignSelf: 'self-start'
                                    }}
                                >
                                    ₱
                                </p>
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        // color: 'rgb(111, 111, 111)',
                                        fontFamily: 'PoppinsSemiBold',
                                        color: '#939598',
                                        fontSize: '44px',
                                        fontWeight: 700,
                                        lineHeight: '44px'
                                    }}
                                >
                                    &nbsp;{(plan.maxCov ?? 0).toLocaleString()}
                                </p>
                            </Stack>
                            <StyledPlanDetails totalAnnual={1}>
                                Total annual reimbursement limit for covered cyber events
                            </StyledPlanDetails>
                        </Stack>
                        {/* <StyledPlanButton onClick={handleSelectPlan}>AVAIL NOW</StyledPlanButton> */}
                        {/* <StyledPlanLink onClick={handleSelectPlan}>Learn more</StyledPlanLink> */}
                        <StyledPlanLink
                            onClick={() =>
                                window.open(
                                    'https://www.bpi.com.ph/personal/cards/debit-cards/debit-mastercard/personal-cyber-insurance',
                                    'bpims'
                                )
                            }
                        >
                            Learn more
                        </StyledPlanLink>
                    </Stack>
                </Card.Body>
            </Card>
        </StyledCol>
    );
};
