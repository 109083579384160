import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';

import BpiWhiteImage from '../assets/images/bpi-logo-white.png';
import BpiRedImage from '../assets/images/bpilogo.png';

const StyledNavbar = styled(Navbar)`
    // background-color: #7e79ad;
    background-color: rgba(255, 255, 255, 0.25);
    &&& {
        padding-top: 2px;
    }

    @media (max-width: 768px) {
        background: linear-gradient(180deg, rgba(242, 249, 249, 0.6587009803921569) 100%, rgba(255, 255, 21, 0) 100%);
        padding: 2px 10px 0 10px;
        background-color: white;
    }
`;

const StyledContainer = styled(Container)`
    &&& {
        padding: 5px 15px;
    }

    @media (max-width: 768px) {
        justify-content: start !important;
        gap: 10px;
    }
`;

const StyledNav = styled(Nav)`
    &&& {
        font-family: PoppinsRegular, sans-serif;
        text-align: center;
    }
`;

const StyledNavLink = styled(Nav.Link)`
    &&& {
        margin-right: 40px;
        letter-spacing: 0.5px;
    }
    color: #fff;

    @media (max-width: 768px) {
        text-align: left;
        color: #303030;
        color: black;
    }
`;

const StyledImageDiv = styled.div`
    min-height: 54px;
`;

const StyledImage = styled(Image)`
    margin-top: 10px;
    margin-left: 10px;
    max-height: 35px;
    content: url(${BpiWhiteImage});

    @media (max-width: 768px) {
        content: url(${BpiRedImage});
        max-height: 54px;
        margin: 0px;
    }
`;

export default function AppNavbar({ isNavbarExpanded, setNavbarExpanded }) {
    return (
        <StyledNavbar expand="lg">
            <StyledContainer>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => {
                        setNavbarExpanded(!isNavbarExpanded);
                    }}
                ></Navbar.Toggle>
                <Navbar.Brand className="pb-0">
                    {/* <Image src={require('../assets/images/bpi-logo-white.png')} style={{ maxHeight: '54px' }} fluid /> */}
                    <StyledImageDiv>
                        <StyledImage fluid />
                    </StyledImageDiv>
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav" style={{ flexDirection: 'column' }}>
                    <StyledNav className="justify-content-center">
                        <StyledNavLink href="https://www.bpi.com.ph/" target="bpims">
                            BPI
                        </StyledNavLink>
                        <StyledNavLink href="https://bpims.com/" target="bpims">
                            BPI MS INSURANCE
                        </StyledNavLink>
                        <StyledNavLink
                            href="https://bpims.com/wp-content/uploads/2024/03/PERSONAL-CYBER_TCs-11Oct2023.pdf"
                            target="bpims"
                        >
                            TERMS & CONDITIONS
                        </StyledNavLink>
                        <StyledNavLink href="#contact-us" id="contact-us-button">
                            CONTACT US
                        </StyledNavLink>
                    </StyledNav>
                </Navbar.Collapse>
            </StyledContainer>
        </StyledNavbar>
    );
}
